import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from '@/languages/es'

Vue.use(Vuetify);

const theme = {
  dark: true,
  options: { customProperties: true },
  themes: {
    dark: {
      celeste: {
        base: '#2d80fa'
      },
      turquesa: {
        base: '#03ffe2'
      },      
      azul1: {
        base: '#3C6CCE'
      },
      azul2: {
        base: '#423FD8'
      },
      azul3: {
        base: '#3B3BD1'
      },
      azul4: {
        base: '#29298E'
      },
      violeta: {
        base: '#8B33D1'
      },
      gris: {
       base:  '#414147'
      },
      body: {
        base: '#111828'
      },
      body2: {
        base: '#0f2d5f'
      },
      header: {
        base: '#242496'
      },
      footer: {
        base: '#E6E6ED'
      }
    }
  }
}

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es'
  },
  theme
});
