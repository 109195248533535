import es from 'vuetify/lib/locale/es';

export default {
  ...es,
  home: 'HOME',
  nosotros: 'NOSOTROS',
  litepaper: 'LITEPAPER',
  contacto: 'CONTACTO',
  subasta: 'SUBASTA',
  inscripcion: 'INSCRIPCION',
  login: 'LOGIN',
  logout: 'Logout'
}