import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "main" */ '@/views/MainLayout.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home/Home')
      },
      {
        path: '/nosotros',
        name: 'nosotros',
        component: () => import(/* webpackChunkName: "nosotros" */ '@/views/Nosotros/Nosotros.vue')
      },
      {
        path: '/contacto',
        name: 'contacto',
        component: () => import(/* webpackChunkName: "nosotros" */ '@/views/Contacto/Contacto.vue')
      },
      {
        path: '/nft',
        name: 'nft',
        component: () => import(/* webpackChunkName: "nosotros" */ '@/views/NFT/NFT.vue')
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { x: 0, y: 0, behavior: 'smooth' }
  }
})

export default router
