<template>
  <v-app ref="app" v-cloak v-scroll="trackScroll">
    <v-app-bar flat app color="header" class="nav-position">

      <v-toolbar-title><router-link to="/"><v-img width="155px" src="assets/tokenfit-logo-min.svg" /></router-link></v-toolbar-title>
      <v-spacer />

      <template v-if="!$vuetify.breakpoint.mobile">

        <template v-for="(seccion, i) in secciones">

          <v-menu offset-y v-if="seccion.items" :key="`menu-item-${seccion.label}`">
            <template v-slot:activator="{on}">
              <v-btn
                  plain 
                  text
                  :class="$route.name == seccion.label.toLowerCase() ? 'turquesa--text v-btn--active' : ''" 
                  :ripple="false"
                  v-on="on">
                  <span class="font-weight-black">{{ $vuetify.lang.t(seccion.t)}}</span>
              </v-btn>
            </template>
              <v-list flat color="header" tile>
                <v-list-item
                  exact-active-class='turquesa--text' 
                  v-for="(submenu) in seccion.items" :key="`submenu-${submenu.label}`"
                  :to="submenu.notAvailable ? undefined : submenu.to"
                  :href="submenu.notAvailable ? undefined : submenu.href"
                  :target="submenu.href ? '_blank' : ''"
                  @click="submenu.notAvailable ? (showNotAvailable)() : undefined">
                  <v-list-item-title>{{ $vuetify.lang.t(submenu.t)}}</v-list-item-title>
                </v-list-item>
              </v-list> 
          </v-menu>

          <v-btn
            v-if="!seccion.items"
            :key="`seccion-${i}`"
            exact-active-class='turquesa--text' 
            plain 
            text 
            :ripple="false" 
            :to="seccion.to"
            :href="seccion.href"
            :target="seccion.href ? '_blank' : ''"><span class="font-weight-black">{{ $vuetify.lang.t(seccion.t) }}</span></v-btn>        

        </template>
      </template>
      
      <!-- Menu para mobile -->
      <v-menu offset-y v-if="$vuetify.breakpoint.mobile" content-class="full-menu">
        <template v-slot:activator="{ on }">
          <v-app-bar-nav-icon color="celeste" :rippple="false" v-on="on"/>
        </template>

        <v-list flat color="header" tile elevation="0"  min-width="100%">

          <template  v-for="(seccion, i) in secciones">

            <template v-if="seccion.items">

              <v-list-item
                v-for="submenu in seccion.items"
                :key="`seccionlist-${submenu.label}`"
                :to="submenu.notAvailable ? undefined : submenu.to"
                :href="submenu.notAvailable ? undefined : submenu.href"
                :target="submenu.href ? '_blank' : ''"
                exact-active-class='turquesa--text' 
                :ripple="false"
                @click="submenu.notAvailable ? (showNotAvailable)() : undefined">
                <v-list-item-title>{{ $vuetify.lang.t(submenu.t) }}</v-list-item-title>
              </v-list-item>
            </template>
          
             <template v-else>

              <v-list-item
                :key="`seccionlist-${i}`"
                :to="seccion.to"
                :href="seccion.href"
                :target="seccion.href ? '_blank' : ''"
                exact-active-class='turquesa--text' 
                :ripple="false">
                <v-list-item-title>{{ $vuetify.lang.t(seccion.t) }}</v-list-item-title>
              </v-list-item>
             </template>
         
          </template>
        </v-list>

      </v-menu>

    </v-app-bar>

    <v-snackbar
      v-model="snackbar.show"
      top
      :color="snackbar.color"
      class="snack-position mt-12"
      >
      <span class="text-center justify-center text-uppercase white--text font-weight-bold">{{snackbar.msg}}</span>
    </v-snackbar>
    <v-main>


      <router-view :secciones="secciones" :rrss="rrss" :offsetTop="offsetTop" /> 

    </v-main>

    <r-r-s-s-toolbar :socials="rrss" v-if="!$vuetify.breakpoint.mobile"/>

  </v-app>
</template>
<script>
const gitbook_url = process.env.VUE_APP_GITBOOK_URL;
import EventBus from '@/utils/eventbus'
export default {
  name: 'App',
  components: {
    RRSSToolbar: () => import('@/views/components/RRSSToolbar')
  },
  data() {
    return {
      // Las secciones son tomadas global en todos los compopnentes con la siguiente definición
      // Si el objeto tiene propiedad to, se interpreta como un link interno
      // Su el objeto tiene propiedad href se interpreta como link externo y requirere utilizar target
      secciones: [
        {to: '/', label: 'Home', t: '$vuetify.home'},
        {type: 'menu', label: 'Nosotros', t: '$vuetify.nosotros', items: [
          {to: 'nosotros', label: 'Nosotros', t: '$vuetify.nosotros'},
          {href: gitbook_url, label: 'Litepaper', t: '$vuetify.litepaper', target: "_blank"},
        ]},
        {type: 'menu', label: 'Subasta', t: '$vuetify.subasta', items: [
          {to: 'inscripcion', label: 'Inscripción', t: '$vuetify.inscripcion', notAvailable: true},
          {to: 'subasta', label: 'Subasta', t: '$vuetify.subasta', notAvailable: true},
        ]},
        {to: 'contacto', label: 'Contacto', t: '$vuetify.contacto', noFooter: true},
      ],   
      rrss: [
        //{label: '@Linkedin', link: '', img: 'assets/ic-rrss-linkedin.svg'},
        {label: '@Instagram', link: 'https://www.instagram.com/tokenfit_io/', img: 'assets/ic-rrss-instagram.svg', min: 'assets/ic-rrss-instagram-min.svg'},
        {label: '@Twitter', link: 'https://twitter.com/tokenfit_io', img: 'assets/ic-rrss-twitter.svg', min: 'assets/ic-rrss-twitter-min.svg'},
        {label: '@Facebook', link: 'https://www.facebook.com/tokenfit', img: 'assets/ic-rrss-facebook.svg', min: 'assets/ic-rrss-facebook-min.svg'},
      ],
      snackbar:{ 
        show: false,
        msg: 'EMAIL ENVIADO OK',
        color: 'success'
      },
      offsetTop: 0,
    }
  },
  methods: {
    addBlur() {
      const appWrapper = document.querySelector('.v-application--wrap');
      appWrapper.classList.add('blur-filter');
    },
    removeBlur() {
      const appWrapper = document.querySelector('.v-application--wrap');
      appWrapper.classList.remove('blur-filter');      
    },
    showSnackbar(snackbarData) {
      this.snackbar.msg = snackbarData.msg || 'ERROR'
      this.snackbar.color = snackbarData.color || 'red accent-2'
      this.snackbar.show = true
    },
    trackScroll(e) {
      this.offsetTop = e.target.scrollingElement.scrollTop
    },
    showNotAvailable() {
      EventBus.$emit('show-news-dialog',{ title: 'INSCRIBITE', withSoon: true})
    },
  },
  created() {
    EventBus.$on('show-dialog', this.addBlur)
    EventBus.$on('hide-dialog', this.removeBlur)
    EventBus.$on('show-snackbar', this.showSnackbar)
    document.body.classList.add('blur-filter')
  },
  mounted() {
    document.body.classList.remove('blur-filter')
  }

}
</script>
<style>
.nav-position .v-toolbar__content{
  max-width: 1178px;
  margin: auto;
}
.snack-position {
  z-index: 999px;
}
.full-menu {
  width: 100%;
  max-width: 100% !important;
  left: 0px !important;
  right: 0 !important;
}
</style>
