import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
//import ACL from './plugins/acl'
//import store from './store'
import router from './router'
import '@/sass/custom.scss'
import capitalize from '@/utils/capitalize'

//Vue.use(ACL)

Vue.config.productionTip = false

Vue.filter('capitalize', capitalize)

new Vue({
  vuetify,
//  store,
  router,
  render: h => h(App),
/*  methods: {
    getUserRoles() {
      return this.$store.getters['user/getUserRoles'];
    }
  },
*/
  mounted() {
    // Configure function for getting roles to ACL plugin
    // this.setGetRolesFunction(this.getUserRoles)
  }
}).$mount('#app')
